/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */

import Mailto from './mailto';

const Contact = (props) => {
  const { data } = props;

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="section-title text-center">
                <h2>{data ? data.title.header : 'loading'}</h2>
                <h3>{data ? data.title.subheading : 'loading'}</h3>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-custom btn-lg"
                  onClick={() => location.href = 'http://webapp.xraysion.com'}
                >
                  Signup and Upload your data
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <h3>
                  Service provided by
                  {' '}
                  {' '}
                  <a href="http://www.1000shapes.com" rel="nofollow">
                    1000shapes GmbH
                  </a>
                  {'  '}
                  {' '}
                  &copy; 2021
                </h3>
                <div>
                  <p>If you have questions, feel free to contact us at:</p>
                </div>
                <ul>
                  <li>
                    <a href={data ? data.linkedin : '/'}>
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  <li>
                    <Mailto
                      email={data ? data.email : ''}
                      subject={data ? data.emailParams.subject : ''}
                      body={data ? data.emailParams.body : ''}
                    >
                      <i className="fa fa-envelope" />
                    </Mailto>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="col-md-12">
          <div className="row">
            <div className="content text-center">
              <ul>
                {data ? data.footer.map((link, i) => (
                  <li key={`footer-link-${i}`}>
                    <h3>
                      <a href={link.href} rel="nofollow">
                        {link.text}
                      </a>
                    </h3>
                  </li>
                )) : 'loading'}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
