/* eslint-disable react/prop-types */

// Credit: https://www.30secondsofcode.org/react/s/mailto
const Mailto = ({
  email, subject = '', body = '', children
}) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};

export default Mailto;
