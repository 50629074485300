/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';

const overlay = (img, title, paragraph) => (
  <div className="overlay-wrapper">
    <div className="hero-text-overlay">
      <h2>{title}</h2>
      <h3>{paragraph}</h3>
    </div>
    <img src={`img/hero/${img}`} className="img-responsive" alt="" />
  </div>
);

const Hero = (props) => {
  const { t } = useTranslation();

  return (
    <div id="hero">
      {props.data ? overlay(props.data.img, t('Hero.header'), t('Hero.text')) : 'Loading'}
    </div>
  );
};

export default Hero;
