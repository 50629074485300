import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './i18n';
// import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
  () => {
    // add css
    ['css/bootstrap.min.css',
      'fonts/font-awesome/css/font-awesome.css',
      'css/style.css',
      // 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap',
      // 'https://fonts.googleapis.com/css?family=Lato:400,700&display=swap',
      'https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800,900&display=swap']
      .forEach((url) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = url;
        document.head.appendChild(link);
      });
  }
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
