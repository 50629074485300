import { Suspense, useState, useEffect } from 'react';
import SmoothScroll from 'smooth-scroll';
import Navigation from './components/navigation';
import About from './components/about';
import Features from './components/features';
import Service from './components/service';
import Contact from './components/contact';
import JsonData from './data/data.json';
import Hero from './components/hero';

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

// For configuration see https://github.com/cferdinandi/smooth-scroll
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  durationMax: 300,
  easing: 'Linear', // Easing pattern to use
  //  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <div>
        <Navigation />
        <Hero data={landingPageData.Hero} />
        <About data={landingPageData.About} />
        <Features data={landingPageData.Features} />
        <Service data={landingPageData.Service} />
        <Contact data={landingPageData.Contact} />
      </div>
    </Suspense>
  );
};

export default App;
