/* eslint-disable quotes */
/* eslint-disable quote-props */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-globals */
// import i18n from 'src/i18n';
import { useTranslation } from 'react-i18next';
// import { useState } from 'react';
// import ReactFlagsSelect from 'react-flags-select';
import Logo from './logo';

// TODO: move to a language config
// const languages = ['US', 'DE'];
// const customLabels = { "US": "English", "DE": "Deutsch" };
// const countryLangMap = new Map([
//   ['US', 'en'],
//   ['DE', 'de']
// ]);

const Navigation = () => {
  // const [selected, setSelected] = useState('');
  const { t } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(countryLangMap.get(lng));
  // };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <a href="https://www.xraysion.com" target="_blank" rel="noreferrer">
            <Logo className="nav-logo" />
          </a>
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
            aria-expanded="false"
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a id="nav-about" href="#about" className="page-scroll">
                <strong>{t('Navbar.About')}</strong>
              </a>
            </li>
            <li>
              <a id="nav-features" href="#features" className="page-scroll">
                <strong>{t('Navbar.Features')}</strong>
              </a>
            </li>
            <li>
              <a id="nav-service" href="#service" className="page-scroll">
                <strong>{t('Navbar.Service')}</strong>
              </a>
            </li>
            <li>
              <button
                type="button"
                href="#contact"
                className="btn-custom"
                onClick={() => location.href = "https://webapp.xraysion.com/"}
              >
                <strong>{t('Navbar.Signup')}</strong>
              </button>
            </li>
            {/* <li className="flags-language-selector"> */}
            {/* See https://github.com/maxruby/react-flags-select */}
            {/* <ReactFlagsSelect
                countries={languages}
                customLabels={customLabels}
                selected={selected}
                selectedSize={14}
                placeholder="Select Language"
                onSelect={(code) => { changeLanguage(code); setSelected(code); }}
              /> */}
            {/* </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
