/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import { configResponsive, useResponsive } from 'ahooks';

configResponsive({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
});

const isMobile = (xs, sm, md) => (sm && !md) || (xs && !sm);

const Service = (props) => {
  const responsive = useResponsive();

  const createRow = (d, i) => (
    <div key={`${d.name}-${i}`} className="col-xs-12 col-sm-12">
      <div id="row" className="top-buffer" key={`${d.name}-${i}`}>
        <div className="step-circle col-xs-2 col-sm-2">{i + 1}</div>
        <div className="col-xs-2 col-sm-2">
          <img src={d.img} alt="..." className="service-img" />
        </div>
        <div className="col-xs-8 col-sm-8 caption">
          <h4>{ d.text }</h4>
        </div>
      </div>
    </div>
  );

  return (
    <div id="service" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>{props.data ? props.data.header : 'loading'}</h2>
        </div>
        { !isMobile(responsive.xs, responsive.sm, responsive.md) ? (
          <div id="row">
            {props.data
              ? props.data.list.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 col-xs-2 service-column">
                  <div className="thumbnail">
                    {' '}
                    <img src={d.img} alt={d.name} loading="lazy" className="service-img" />
                    <div className="step-circle">
                      {i + 1}
                    </div>
                    <div className="caption">
                      <h4>{ d.text }</h4>
                    </div>
                  </div>
                </div>
              ))
              : 'loading'}
          </div>
        )
          : (
            <>
              { props.data ? props.data.list.map((d, i) => (createRow(d, i))) : 'loading' }
            </>
          )}
      </div>
    </div>
  );
};

export default Service;
