/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import { configResponsive, useResponsive } from 'ahooks';

configResponsive({
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
});

const isNotDesktop = (lg) => (!lg);

const description = (title, paragraph) => (
  <div className="col-xs-12 col-md-6">
    <div className="about-text">
      <h2>{title}</h2>
      <h3>{paragraph}</h3>
    </div>
  </div>
);

const img = (name) => (
  <div className="col-xs-12 col-md-6">
    {' '}
    <img src={`img/about/${name}`} className="img-responsive" loading="lazy" alt="" />
    {' '}
  </div>
);

const About = (props) => {
  const { t } = useTranslation();
  const responsive = useResponsive();

  const createAboutEntry = (notDesktop, tagline, i) => {
    if (tagline) {
      if (notDesktop) {
        return (
          <>
            { description(t(`About.list.item${i + 1}.title`), t(`About.list.item${i + 1}.paragraph`)) }
            { img(t(`About.list.item${i + 1}.img`)) }
          </>
        );
      }
      return (
        <>
          { tagline.textAlignment === 'left' ? description(t(`About.list.item${i + 1}.title`), t(`About.list.item${i + 1}.paragraph`)) : img(t(`About.list.item${i + 1}.img`)) }
          { tagline.textAlignment === 'left' ? img(t(`About.list.item${i + 1}.img`)) : description(t(`About.list.item${i + 1}.title`), t(`About.list.item${i + 1}.paragraph`)) }
        </>
      );
    }

    return 'loading';
  };

  return (
    <div id="about">
      { props.data ? props.data.map((tagline, i) => (
        <div className={tagline.backgroundColor} key={i}>
          <div className="container">
            <div className="row">
              { createAboutEntry(isNotDesktop(responsive.lg), tagline, i) }

            </div>
          </div>
        </div>
      )) : 'loading' }
    </div>
  );
};

export default About;
