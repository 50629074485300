/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

const formatBrandAsBold = (text) => {
  const findAtStart = /^xraysion(.+)/;
  const findAtMiddle = /(.+)xraysion(.+)/;

  const atStart = text.match(findAtStart);
  if (atStart) {
    return (
      <h2>
        <strong>xraysion</strong>
        {atStart[1]}
      </h2>
    );
  }

  const atMiddle = text.match(findAtMiddle);
  if (atMiddle) {
    return (
      <h2>
        {atMiddle[1]}
        <strong>xraysion</strong>
        {atMiddle[2]}
      </h2>
    );
  }

  return <h3>{text}</h3>;
};

const Features = (props) => (
  <div id="features" className="text-center">
    <div className="container">
      <div className="col-md-8 col-md-offset-2 section-title">
        <h2>{props.data ? props.data.header : 'loading'}</h2>
      </div>
      <div id="row  align-items-center">
        {props.data
          ? props.data.list.map((d, i) => (
            <div key={`${d.title}-${i}`} className="col-md-6 col-sm-6 features">
              <div className="thumbnail">
                <div className="thumbnail-caption text-center">
                  <h2>{d.title}</h2>
                </div>
                <div className="thumbnail-text caption">
                  {formatBrandAsBold(d.text)}
                </div>
                {d.img ? (<img src={d.img} alt={d.title} loading="lazy" className="features-img" />) : null}
              </div>
            </div>
          ))
          : 'loading'}
      </div>
    </div>
  </div>
);

export default Features;
